/* Home page */

/* Sections of the page */
import About from './About';
import Services from './Services';
import PreviousPartners from './PreviousPartners';
import Resume from './Resume';

import DustinReeseText from '../components/DustinReeseText'

/* Boostrap */
import { Container, Row, Col, Button } from 'react-bootstrap';

/* Links */
import { HashLink } from 'react-router-hash-link';

/* alternating rows */
let alternateRow = true;

function rowAlternating(){
  alternateRow = !alternateRow;

  return(
    alternateRow ? ( "bg-primary-light" ) : ( "" )

  )
}

const Home = () => {
  return (
    <div id="Home">
        <Container fluid>
          <Row id="top" className="text-center bg-dino text-light">
            <Col>

              <Container>
                <Row>
                  <Col>
                    <DustinReeseText />
                
                    <h2>UX, Project Management, Graphic Design</h2>      
                      
                    <hr />

                    <p>A one-stop shop for design, web site creation and illustration</p>

                    <HashLink to="/#about"><Button variant="primary" className="p-3">Learn about Dustin</Button></HashLink>
                  
                  </Col>

                </Row>

              </Container>

            </Col>

          </Row>

          <Row id="about" className={ rowAlternating() } >
            <Col className="">
                <About
                  id="About"
                  homepage={ true }
                />
            </Col>
          </Row>
          
          <Row id="services" className={ rowAlternating() }>
            <Col className="">
              <Services
                id="Services"
                homepage={ true }
              />
            </Col>
          </Row>
          
          <Row id="prevoiusclients" className={ rowAlternating() }>
            <Col className="">
              <PreviousPartners 
                id="PreviousPartners"
                homepage={ true }
              />
            </Col>
          </Row>
                    
          <Row id="resume" className={ rowAlternating() } >
            <Col className="">
              <Resume 
                id="Resume"
                homepage={ true }
              />
            </Col>
          </Row>

        </Container>

    </div>

  );
};
  
export default Home;