/* Previous Clients */

/* React Dom */
import { HashLink } from 'react-router-hash-link';

/* Bootstrap */
import { Container, Row, Col, Image, Button } from 'react-bootstrap';

/* Data */
const data = {}

// Companies
data.previousCustomers = [
  {
    name: "Oregon Mutual",
    logo:"OM.png"
  },
  {
    name: "Summa Realty",
    logo:"summa.png"
  },
  {
    name: "Kaiser Permanenta",
    logo: "KP.svg"
  },
  {
    name: "Lithia Auto Stores",
    logo:"LithiaMotors.jpg"
  },
  {
    name: "Guilde Internationale Des Fromagers",
    logo:"GuileDesFromagres.png"
  },
  {
    name: "Robert G Dolton",
    logo:"RobertDolton.png"
  },
  {
    name: "YouRNurse Consulting",
    logo:"YourNurse.png"
  },
  {
    name: "Specialty Pharmacy",
    logo:"Specialty-Compounding.png"
  },
  {
    name: "The Law Office of Elizabeth J. Inayoshi",
    logo:"EJILaw.png"
  },
  {
    name: "BrokerageBox.com",
    logo:"broker-box.svg"
  }
]

// Quotes
data.quotes = [
  {
    author: "Aalon C. Cole",
    company: "Oregon Mutual",
    quote: "Dusty and I worked together at OMI for 4 1/2 years. He was brought in to punch up our clunky layouts and add some actual design to our apps. He integrated very smoothly into our team and into a project that was already quite far along. Dusty has always been very easy going and enthusiastic with a positive attitude, even through dynamic circumstances. I would welcome the opportunity to work with Dusty in the future as I have found him to be a talented designer, a dependable teammate, a relentless cheerleader, and someone who makes himself available to lend a hand when needed."
  },
  {
    author: "Sean Ezell",
    company: "Oregon Mutual",
    quote: "Dusty has been an amazing co-worker. His eye for UI/UX design is excellent and he has a solid grasp of the programming required behind the scenes. He is flexible and very easy to work with in a team setting, working together across disciplines to reach shared goals. I have been very impressed with his ability as a new Product Owner to take responsibility of the product we're working on and building out his vision. I would be happy to work with Dusty any time in the future."
  },
  {
    author: "Supervisor",
    company: "Oregon Mutual",
    quote: "Dustin’s unique perspective as a product owner and UX engineer on our project helped seamlessly bridge the gap between design and development and ensured that our product not only met but exceeded our customers’ expectations."
  },
  {
    author: "Dusty Reese",
    company: "DustinReese.com",
    quote: "I am always impressed by Dustin's commitement to quality and customer satisfaction. They built me an amazing website that I use all the time to reach out to new customers. I'm so proud, I show it to all my friends and family. I'm not only the DustinReese.com president, but I'm also a client!"
  }
]

/*

  {
    show: false,
    author: "Cathy Hutchison",
    company: "YourNurse.net",
    quote: "A satisfied customer?  ABSOLUTELY!!  After several other failed attempts with other companies, I found this experience to be very easy. He was only a phone call or email away.....no waiting on hold for hours.  He took the time to find out what worked for me and my prospective customers.  The finished product is just what I wanted.  My website was finished very promptly.  I found the pricing to be reasonable, too.  Will I recommend him to others?  DEFINITELY!!"
  }
*/

// Shuffle array
const shuffle = ( array ) => { 
  return array.map((a) => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map((a) => a.value); 

}; 

function PreviousPartners( { id, className, homepage } ) {
  const quotes =  shuffle( data.quotes );

  return (
    <section id={ id } className={ className + " jumbotron text-center mb-3" } >
      { homepage ? "" : <div className="bg-crow bg-fullScreen bg-color-secondary"></div> }

      <Container>
          <Row>
            <Col>
              <h2>Companies that love Dustin's work</h2>
              <hr />

            </Col>

          </Row>

          <Row className='mb-3'>
            <Col>
              <div className="slider">
                <div className="slide-track">                
                  { data.previousCustomers.map( ( company, i ) => (              
                    <div className="slide" title={ company.name } key={ "previousCustomers_" + i }>
                      <Image src={ require("../assets/images/logo-" + company.logo ) } title={ company.name + " logo" } className="" />

                    </div>

                  ))}

                </div>

              </div>
            </Col>     

          </Row>

          <Row>
            <Col md="12" className="mt-3 mb-3">
                  <h2>The reviews are in!</h2>
                  <h4>Here's what people are saying</h4>

                  <hr />

            </Col>

          </Row>

          <Row>
            { quotes.map( ( quote, i ) => (
              <Col id={ "quote_" + i } md="6" key={ id + "_quote_" + i } >
                <div className={ "quotes word_art" + i } >
                  <div className="body">
                    <blockquote className="blockquote mb-0">
                      <p>
                        { quote.quote }
                      </p>

                      <div className="author">
                        <div className="name">{ quote.author }</div>
                        { "company" in quote ? <div className="company">{ quote.company }</div> : "" }
                        

                      </div>

                    </blockquote>

                  </div>

                </div>

              </Col>
            )
            )}

          </Row>

          { homepage ? "" : <div>
            <Row><Col><HashLink to="/Contact" id="nav-contact" title="Contact Dustin"><Button>Contact Dustin today!</Button></HashLink></Col></Row>

          </div> }

      </Container>

    </section>
  );
}

export default PreviousPartners;