/* Services */

// Component
import CardIcon from '../components/CardIcon';

/* React Dom */
import { HashLink } from 'react-router-hash-link';

/* Bootstrap */
import { Container, Row, Col, Button } from 'react-bootstrap';

/* Font Awesome */
import { faDesktop, faEnvelope, faFlask, faListCheck } from '@fortawesome/free-solid-svg-icons';


const data = {
    availableFor: [
      {
        id: "websiteDesign",
        icon: faDesktop,
        title: "Web site design",
        body: "Using modern tools like Figma and the Adobe Creative Suite, I create eye catching designs that easily translate to all screen sizes."
      },
      {
        id: "projectManagement",
        icon: faListCheck,
        title: "Project Management",
        body: "Project planning from idea to the finish line. Using work methodologies like Agile, Kanbam, and Scrum, Dustin can plan out the steps, coordinate teams and manage backlogs to effectively deliver your project on time."
      },
      {
        id: "emailMarketing",
        icon: faEnvelope,
        title: "Email marketing",
        body: "Stay in touch with your customer base with amazing e-mail blast advertising to help drive sales, engage users and expand your audience."
      }
    ]
};

// The work
function Services( {id, className, homepage } ) {
  return (
    <section id={id} className={ className + " text-center" }>
        <Container>  
          <Row>
            <Col>
              <h2>What can Dustin do?</h2>
              <div>More like what CAN'T Dustin do?</div>       
              <hr />
            
            </Col>
            
          </Row>    

          <Row className="justify-content-md-center">
              { data.availableFor.map( ( item, i ) => (
                  <Col key={ "service_" + i } id={ "service_" + i } lg="4" className="mb-3" >
                      <CardIcon
                          id={ item.name }
                          icon= { item.icon }
                          title= { item.title }
                          body= { item.body }
                      />

                  </Col>

              ))}

              <Col id="service_youridea"  lg="6" className="mb-3">
                  <CardIcon
                      id = "madeWithAwesome"
                      icon = { faFlask }
                      title = "Your Awesome Idea"
                      body = "That's rigth. Your amazing, awesome, world melting idea. It's in there and I think it's time you brought it out for everyone to see."
                  />
                
              </Col>

              { homepage ? "" : <div>
                <Col><HashLink to="/Contact" id="nav-contact" title="Contact Dustin"><Button>Contact Dustin today!</Button></HashLink></Col>

              </div> }

          </Row>

        </Container>
        

    </section>
  );
}

export default Services;