// Global values

// React
import React, { useState, useContext } from "react";

// Global reference
const GlobalContext = React.createContext();

// Func: Use Context
export function useGlobals() {
  return useContext( GlobalContext );

}

// Func: Wrapper
export function GlobalVar( { children } ) {
  const [ vals, setGVars ] = useState( {
    greetings: "hi",
    fontSize: "16px",
    fontSizeDefault: "16px",
    theme: "light",
    resume: "standard",
    cookies:{
      completed: false,
      keys:{
        key1: false,
        key2: false,
        key3: false
      }
    }
  } );

  return (
    <GlobalContext.Provider value={ { vals, setGVars } }>
      { children }
      
    </GlobalContext.Provider>

  );

}