/* Components - CardIcon */

/* Font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Bootstrap
import Card from 'react-bootstrap/Card';

function CardIcon( {id, icon, title, body } ) {
    return (
        <Card id={ id } className='h-100'>
            <Card.Body>
                <Card.Title className="d-flex text-start">
                    <FontAwesomeIcon icon={ icon } className="d-none d-sm-block"/>

                    <div className="flex-fil ps-2" >
                        { title }

                    </div>

                </Card.Title>

                <Card.Text>
                    { body }
                    
                </Card.Text>
                
            </Card.Body>

        </Card>
    )
}

export default CardIcon;