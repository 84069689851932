/* About */

/* Bootstrap */
import { Container, Row, Col, Button, Tab, Tabs } from 'react-bootstrap';

/* Links */
import { HashLink } from 'react-router-hash-link';

/* Data */
import dataAbout from '../data/about.json';

// Quest Icon
import QuestIcon from '../components/QuestIcon'

// The work
function About( { id, className, homepage } ) {
  return (
    <section id={ id } className={ "text-center section " + className }>
      { homepage ? "" : <div className="bg-abstract bg-fullScreen"></div> }

      <Container>
        <Row>
          <Col>
            <h2>About Dustin Reese</h2>
            <hr />

          </Col>

        </Row>

        <Row className="justify-content-md-center">
            <Col md="8" className={ "text-start mb-3 aboutContent " + ( homepage ? "" : "fullPage" ) }>
              <Tabs
                id="tab-about"
                className=""
                fill
              >
                <Tab eventKey="basics" title="The Basics">
                  { homepage ? "" : <div><h3>The Basics</h3></div> }
                  { dataAbout.about.map( ( chunk, i ) => (
                    <p key={ id + "_about_about" + i } id={ id + "_paragraph_" + i }>
                      { chunk }

                    </p>

                  ))}
                  
                  <QuestIcon id="key2" />

                </Tab>

                <Tab eventKey="iterative" title="Iterative Design">
                  { homepage ? "" : <div><h3>Iterative Design</h3></div> }
                  { dataAbout.iterative.map( ( chunk, i ) => (
                    <p key={ id + "_about_iterative" + i } id={ id + "_paragraph_" + i }>
                      { chunk }

                    </p>

                  ))}
                </Tab>

                <Tab eventKey="userResearch" title="User Research">
                  { homepage ? "" : <div><h3>User Research</h3></div> }
                  { dataAbout.research.map( ( chunk, i ) => (
                    <p key={ id + "_about_research" + i } id={ id + "_paragraph_" + i }>
                      { chunk }

                    </p>

                  ))}

                </Tab>

                <Tab eventKey="fun" title="Fun Facts">                    
                  { homepage ? "" : <div><h3>Fun Facts</h3></div> }
                  { dataAbout.fun.map( ( chunk, i ) => (
                    <p key={ id + "_about_fun" + i } id={ id + "_paragraph_" + i }>
                      { chunk }

                    </p>

                  ))}

                </Tab>

                { homepage ? 
                <Tab eventKey="secret" title="Secret to Happiness" disabled className="cursor-deny">
                  { dataAbout.secret.map( ( chunk, i ) => (
                    <p key={ id + "_about_secret" + i } id={ id + "_paragraph_" + i }>
                      { chunk }

                    </p>

                  ))}

                </Tab>
                : "" }

              </Tabs>

            </Col>
            
            { homepage ? <Col md="12" >
              <div className="text-center">
                <HashLink to="/#services"><Button variant="primary" className="p-3">Tell me what this magical human do!</Button></HashLink>

              </div>

            </Col> : <HashLink to="/Contact" id="nav-contact" title="Contact Dustin"><Button>Contact Dustin today!</Button></HashLink> }
            
        </Row>
      </Container>  

    </section>
  );
}

export default About;