/* Resume */

// React

// Global Values
import { useGlobals } from '../Global';

// React Dom
import { HashLink } from 'react-router-hash-link';

// Bootstrap
import { Card, Container, Row, Col, Button } from 'react-bootstrap';

// Data - Resume Basics
import dataBasic from '../data/resume-basics.json';

// Data - Varius Resumes
import dataUX from '../data/resume-ux.json';

const allResume = {
    ux: dataUX
}

// Basic Resume data
let resumeState = {
    summary: dataBasic.summary,
    education: dataBasic.education,

    bullets: dataBasic.bullets,
    companies: dataBasic.companies
}

// The work
function Resume({ id, className, resumeToUse, homepage }) {
    // If we're using alt resume
    if( resumeToUse ){
        console.log( "Updaing resume: " + resumeToUse )

        resumeState.summary = allResume[ resumeToUse ].summary;
        resumeState.bullets = allResume[ resumeToUse ].bullets;
        
    }

    // Get global Vars
    const globals = useGlobals();

    // If we have the new resume, use it!
    if( globals.vals.resume in allResume ){        
        console.log( "Resume Global: " + globals.vals.resume )
        
        resumeState.summary = allResume[ globals.vals.resume ].summary;
        resumeState.bullets = allResume[ globals.vals.resume ].bullets;

    }

    return (
        <section id={ id } className={ className + "" } >            
            { homepage ? "" : <div className="bg-rocket bg-fullScreen"></div> }

            <Container>
                <Row className="justify-content-md-center">
                    <Col lg="8">
                        <div className="text-center" id={ id + "_header"}>
                            <h2>Dustin's amazing work history</h2>
                            <p>UX Engineer and Maker of Awesome</p>

                            <hr />

                        </div>

                        <Card className="mb-3 work-summary" id={ id + "_summary"}>
                            <Card.Body>
                                <Card.Title>High level summary of experience</Card.Title>

                                <Card.Text>
                                    { resumeState.summary }

                                </Card.Text>

                            </Card.Body>

                        </Card>
                        
                        <Card className="mb-3 work-history" id={ id + "_history"}>
                            <Card.Body>
                                <Card.Title>Work history</Card.Title>

                                <div className="card-text">
                                    <Container>

                                    { Object.keys( resumeState.bullets ).map( objKey => 
                                        <Row key={ id + "_company_" + objKey } id={ "Resume " + objKey } className="pb-3" >
                                            <Col md="9" className="underline-box">
                                                <h4 className="pb-0 mb-0">{ resumeState.companies[ objKey ].name }</h4>
                                            </Col>

                                            <Col md="3" className="underline-box">
                                                <div className="text-end">{ resumeState.companies[ objKey ].dateStart } - { resumeState.companies[ objKey ].dateEnd }</div>
                                            </Col>
                                            
                                            <Col md="12">
                                                <div className="lead pb-2">{ resumeState.companies[ objKey ].title }</div>
                                            </Col>

                                            <Col md="12">
                                                <ul className="align-items-start">
                                                    { resumeState.bullets[ objKey ].map( ( bullet, b ) => (
                                                        <li key={ id + "_job_bullets" +  b } >
                                                            { "bold" in bullet ? <strong>{ bullet.bold }: </strong> : "" }
                                                            { bullet.body }
                                                        </li>

                                                    ))}

                                                </ul>

                                                <hr />
                                            
                                            </Col>                                     

                                        </Row>                                  

                                    ) }

                                    </Container>                                                              

                                </div>

                            </Card.Body>

                        </Card>                        

                        {/* Education section */}
                        <Card className="mb-3 work-education" id={ id + "_education" }>
                            <Card.Body>
                                <Card.Title>Education</Card.Title>

                                <div className="card-text">
                                    <Container>
                                        <Row>
                                            <Col md="9">
                                                <h4 className="pb-0 mb-0">{ resumeState.education.school }</h4>
                                            </Col>   
                                            <Col md="3">
                                                <div className="text-end">Graduated - { resumeState.education.dateEnd }</div>
                                            </Col>                                     
                                            <Col md="12">
                                                    <div className="lead pb-2">{ resumeState.education.Degree }</div>
                                                </Col>
                                            <Col>
                                                <div className='fst-italic'>Minors: { resumeState.education.Minors }</div>
                                            </Col>
                                        </Row>
                                    </Container>

                                </div>

                            </Card.Body>

                        </Card>

                        <Row>
                            <Col className="text-center">
                                <HashLink to="/Contact" id="nav-contact" title="Contact Dustin"><Button>Contact Dustin today!</Button></HashLink>

                            </Col>
                        </Row>

                    
                    </Col>
                
                </Row>

            </Container>        

        </section>
    );
}

export default Resume;