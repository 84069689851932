/* Index.js */

// React
import { React, useEffect } from 'react';

// Router
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Pages
import Layout from "./pages/Layout";
import NoPage from "./pages/NoPage";

// Pages - Specific pages
import Home from "./pages/Home";
import About from './pages/About';
import Resume from './pages/Resume';
import Contact from './pages/Contact';
import PreviousPartners from './pages/PreviousPartners';
import Quest from './pages/Quest';

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/index.scss';

// GA4 set up
import ReactGA from 'react-ga4';

// Cookie Consent
import Cookies from 'universal-cookie';
const cookies = new Cookies( null, { path: '/' } );

// The work
const App = () => {
  // Update the name of the page
  useEffect(() => {
    document.title = "DustinReese.com"

    // If we have consent, then go ahead and Google Analytics
    if( cookies.get( "cookie_consent" ) === "yes" ){
      //console.log( "Init: Google Anayltics" )
      ReactGA.initialize( process.env.REACT_APP_GA_ID );

    }

  }, [])

  return (
    <BrowserRouter basename={ process.env.REACT_APP_DEV + "/" } >
      <Routes>
        <Route path={ "/" } element={ <Layout /> }>
          <Route index element={ <Home /> } />

          <Route path={ "/previousClients"} element={ <PreviousPartners id="PreviousPartners" /> } />
          <Route path={ "/resume"} element={ <Resume id="Resume" /> }/>
          <Route path={ "/contact"} element={ <Contact id="Contact" />} />
          <Route path={ "/about"} element={ <About id="About" /> } />
          
          <Route path={ "/quest"} element={ <Quest id="Quest" /> } />     

          <Route path={ "*" } element={ <NoPage /> } />
          
        </Route>

      </Routes>

    </BrowserRouter>
  )
}

export default App;