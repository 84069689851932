// Dynamic Adjustments to site

// React
import { useState } from 'react';

// GA4 - Google Analytics
import ReactGA from 'react-ga4';

// Bootstrap
import { Card, Button, CloseButton, Form, InputGroup } from "react-bootstrap"

/* Font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
/* Font Awesome Icons */
import { faGear, faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons';

// Global settings
import { useGlobals } from '../Global';

// The work
function Settings( { id, classes } ) {
    // Google Analytics
    const gaCategory = "settings";

    // Globals
    const globals = useGlobals();

    // Default font size
    const defaultFontSize = globals.vals.fontSizeDefault;

    // Show settings
    const [ showSetting, setShowSettings ] = useState( false );

    // Update showing
    const handleShow=()=>{
        setShowSettings( !showSetting );

        ReactGA.event({
            category: gaCategory,
            action: "Open/Close",
            label: showSetting
    
        });

    }

    //////////////////////////////////////

    // Font Size
    const [ fontSize, setFontSize ] = useState( defaultFontSize );

    // Update Font size
    const handleFontSize=( e ) => {
        e.preventDefault(); // prevent the default action
        setFontSize( e.target.value + "px" ); // set name to e.target.value (event)

        document.getElementById("root").style.fontSize = e.target.value + "px";

        globals.setGVars( {
            ...globals.vals,
            ...globals.vals.fontSize = e.target.value + "px"
    
        } )

    };

    // Font Default size
    const handleFontDefault=() => {
        setFontSize( defaultFontSize );
        document.getElementById("root").style.fontSize = defaultFontSize;

    }

    //////////////////////////////////////

    // Grey Scale
    const [ greyScaleClass, setGreyScaleClass ] = useState( false );

    // Grey scale function
    const handleGreyScale=() => {
        // Remove black and white class
        if( greyScaleClass ){
            document.getElementById("root").classList.remove("color-bw-root");

        }

        // Add black and white class
        if( !greyScaleClass ){
            document.getElementById("root").classList.add("color-bw-root");

        }
        
        // Switch the grey scale boolean
        setGreyScaleClass( !greyScaleClass )

        ReactGA.event({
            category: gaCategory,
            action: "Grey Scale",
            label: greyScaleClass
    
        });

    }

    //////////////////////////////////////

    // Theme Handle
    const [ pageTheme, setPageTheme ] = useState( globals.vals.theme );

    // Grey scale function
    const handleOldSchool=() => {
        // Default theme
        let newTheme = "light";

        // Make it old school
        if( pageTheme !== "oldSchool" ){
            document.body.classList.add( "oldSchool" );

            newTheme = "oldSchool";

            ReactGA.event({
                category: gaCategory,
                action: "Theme",
                label: "Old School"
        
            });

        }

        // Remove old school
        if( pageTheme === "oldSchool" ){
            document.body.classList.remove( "oldSchool" );

            ReactGA.event({
                category: gaCategory,
                action: "Theme",
                label: "Light"
        
            });
            
        }

        // Update our global val
        globals.vals.theme = newTheme

        // Update theme
        globals.setGVars( {
            ...globals.vals
        } )

        setPageTheme( newTheme )

    }

    return (
        <div className={"settings " + showSetting + " " + classes } id={ id } >
            <Button onClick={ handleShow } className="gear">
                <FontAwesomeIcon icon={ faGear } />
            </Button>

            <Card>
                <Card.Body>
                    <Card.Title className="d-flex">
                        <div className='flex-fill'>
                            Settings
                        </div>

                        <CloseButton onClick={ handleShow }></CloseButton>

                    </Card.Title>

                    <div className="card-text">
                        <div className="d-flex flex-column">
                            <strong>Theme:</strong>
                            <InputGroup className="mb-3 d-flex flex-column">
                                <Form>
                                    <Form.Check
                                        // prettier-ignore
                                        type="switch"
                                        id="oldSchool"
                                        label="90s"

                                        onClick={ handleOldSchool }

                                    />

                                </Form>

                                <Form>
                                    <Form.Check
                                        // prettier-ignore
                                        type="switch"
                                        id="greyScale"
                                        label="Grey Scale"

                                        onClick={ handleGreyScale }
                                    />

                                </Form>

                            </InputGroup>

                            <InputGroup>
                                <label htmlFor="fontSize" className="form-label flex-fill"><strong>Font Size</strong>: { fontSize } </label>

                                <div onClick={ handleFontDefault } className="fontDefault ms-1" title="Default font size" >
                                    <FontAwesomeIcon icon={ faArrowRotateLeft } />
                                </div>

                                <input type="range" className="form-range" id="fontSize" min="10" max="30" step="1" onChange={ handleFontSize } defaultValue={ "16" } ></input>

                            </InputGroup>

                        </div>
                        

                    </div>

                </Card.Body>

            </Card>                  

        </div>

    )

}

export default Settings;