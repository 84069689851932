// A cool text version of Dustin Reese

// The work
const DustinReeseText = () => {
    return(
        <div className='dustinreesetext'>
            <h1 className="dash">-</h1>

            <svg viewBox="-20 -10 300 100" fill="none" xmlns="http://www.w3.org/2000/svg" className="Dustin">
                <g className="shadow">
                    <path className="fill-main letter-d" d="M0 0.911987H29.928C32.616 0.911987 35.4 1.23199 38.28 1.87199C41.16 2.51199 43.784 3.69599 46.152 5.42399C48.584 7.08799 50.568 9.39199 52.104 12.336C53.64 15.28 54.376 19.056 54.312 23.664L54.216 46.8C54.216 51.024 53.384 54.672 51.72 57.744C50.12 60.752 48.072 63.216 45.576 65.136C43.08 67.056 40.296 68.464 37.224 69.36C34.216 70.32 31.24 70.8 28.296 70.8H0.936005V0.911987ZM42.12 27.216C42.12 22.864 41.032 19.696 38.856 17.712C36.744 15.664 33.032 14.64 27.72 14.64H16.392V55.632H28.584C30.312 55.632 31.976 55.472 33.576 55.152C35.24 54.768 36.68 54.16 37.896 53.328C39.176 52.496 40.2 51.376 40.968 49.968C41.736 48.56 42.12 46.768 42.12 44.592V27.216Z" />
                    <path className="fill-main letter-u" d="M90 25.104, H105.353V70.8, H91.6245V66.768C90.0245 68.432 88.3605 69.52 86.6325 70.032C84.9045 70.544 83.3365 70.8 81.9285 70.8, H77.9925C75.8805 70.8 73.8325 70.512 71.8485 69.936C69.9285 69.424 68.2325 68.496 66.7605 67.152C65.3525 65.872 64.2005 64.144 63.3045 61.968C62.4725 59.728 62.0565 56.912 62.0565 53.52V25.104, H73.7685V50.64C73.7685 53.904 74.1845 56.272 75.0165 57.744C75.9125 59.216 77.5445 59.952 79.9125 59.952, H83.7525C85.7365 59.952 87.3045 59.248 88.4565 57.84C89.6725 56.368 90.2805 54.224 90.2805 51.408V25.104Z" />
                    <path className="fill-main letter-s" d="M112 66.576V57.648C115.559 58.928 118.631 60.144 121.703 61.296C124.839 62.448 127.847 62.896 130.727 62.64C131.623 62.64 132.487 62.608 133.319 62.544C134.151 62.416 134.887 62.224 135.527 61.968C136.231 61.712 136.839 61.328 137.351 60.816C137.863 60.24 138.215 59.504 138.407 58.608C138.663 57.52 138.535 56.56 138.023 55.728C137.575 54.832 136.935 54.064 136.103 53.424C135.271 52.784 134.343 52.272 133.319 51.888C132.295 51.504 131.399 51.216 130.631 51.024C128.071 50.384 125.671 49.776 123.431 49.2C121.255 48.56 119.367 47.76 117.767 46.8C116.167 45.84 114.887 44.624 113.927 43.152C113.031 41.68 112.583 39.792 112.583 37.488C112.583 34.416 113.415 31.888 115.079 29.904C116.807 27.92 118.951 26.416 121.511 25.392C124.071 24.304 126.791 23.696 129.671 23.568C132.615 23.376 135.335 23.568 137.831 24.144C139.687 24.592 141.479 25.136 143.207 25.776C144.935 26.416 146.535 27.28 148.007 28.368V38.064C146.919 37.168 145.863 36.4 144.839 35.76C143.815 35.056 142.567 34.384 141.095 33.744C140.135 33.296 139.079 32.944 137.927 32.688C136.775 32.368 135.623 32.208 134.471 32.208C133.319 32.144 132.167 32.24 131.015 32.496C129.927 32.752 128.935 33.232 128.039 33.936C127.335 34.512 126.887 35.152 126.695 35.856C126.503 36.496 126.503 37.136 126.695 37.776C126.951 38.416 127.335 39.024 127.847 39.6C128.359 40.112 128.967 40.464 129.671 40.656C132.295 41.488 134.599 42.256 136.583 42.96C138.631 43.6 140.775 44.368 143.015 45.264C144.807 46.032 146.247 46.896 147.335 47.856C148.423 48.816 149.255 49.872 149.831 51.024C150.407 52.112 150.791 53.264 150.983 54.48C151.175 55.696 151.271 56.912 151.271 58.128C151.271 60.624 150.631 62.768 149.351 64.56C148.135 66.352 146.535 67.824 144.551 68.976C142.631 70.128 140.455 70.96 138.023 71.472C135.655 71.984 133.319 72.24 131.015 72.24C127.559 72.24 124.327 71.76 121.319 70.8C118.375 69.84 115.431 68.432 112.487 66.576Z" />
                    <path className="fill-main letter-t" d="M191 24.24V33.744, H180.907V70.8, H166.795V33.744, H156.619V24.24, H166.795V3.69599, H180.907V24.24, H191.179Z" />
                    <path className="fill-main letter-i" d="M199 16.464V5.13599, H210.981V16.464, H199.365ZM199.365 70.8V25.2, H210.981V70.8, H199.365Z"/>
                    <path className="fill-main letter-n" d="M254 70.8V45.168C254.842 44.208 254.65 43.248 254.266 42.288C253.946 41.328 253.466 40.496 252.826 39.792C252.186 39.088 251.45 38.512 250.618 38.064C249.85 37.616 249.05 37.392 248.218 37.392, H241.69C240.986 37.392 240.25 37.552 239.482 37.872C238.778 38.192 238.106 38.64 237.466 39.216C236.826 39.792 236.282 40.464 235.834 41.232C235.45 42 235.258 42.8 235.258 43.632V70.8, H221.146V24.048, H235.258V27.888C236.154 27.376 237.21 26.96 238.426 26.64C239.642 26.256 240.794 26.064 241.882 26.064, H248.89C251.066 26.064 253.114 26.448 255.034 27.216C256.954 27.984 258.618 29.072 260.026 30.48C261.434 31.888 262.554 33.648 263.386 35.76C264.218 37.808 264.634 40.112 264.634 42.672V70.8, H254.842Z" />
                </g>

                <g id="Dustin" className="name">
                    <path className="fill-main letter-d" d="M0 0.911987H29.928C32.616 0.911987 35.4 1.23199 38.28 1.87199C41.16 2.51199 43.784 3.69599 46.152 5.42399C48.584 7.08799 50.568 9.39199 52.104 12.336C53.64 15.28 54.376 19.056 54.312 23.664L54.216 46.8C54.216 51.024 53.384 54.672 51.72 57.744C50.12 60.752 48.072 63.216 45.576 65.136C43.08 67.056 40.296 68.464 37.224 69.36C34.216 70.32 31.24 70.8 28.296 70.8H0.936005V0.911987ZM42.12 27.216C42.12 22.864 41.032 19.696 38.856 17.712C36.744 15.664 33.032 14.64 27.72 14.64H16.392V55.632H28.584C30.312 55.632 31.976 55.472 33.576 55.152C35.24 54.768 36.68 54.16 37.896 53.328C39.176 52.496 40.2 51.376 40.968 49.968C41.736 48.56 42.12 46.768 42.12 44.592V27.216Z" />
                    <path className="fill-main letter-u" d="M90 25.104, H105.353V70.8, H91.6245V66.768C90.0245 68.432 88.3605 69.52 86.6325 70.032C84.9045 70.544 83.3365 70.8 81.9285 70.8, H77.9925C75.8805 70.8 73.8325 70.512 71.8485 69.936C69.9285 69.424 68.2325 68.496 66.7605 67.152C65.3525 65.872 64.2005 64.144 63.3045 61.968C62.4725 59.728 62.0565 56.912 62.0565 53.52V25.104, H73.7685V50.64C73.7685 53.904 74.1845 56.272 75.0165 57.744C75.9125 59.216 77.5445 59.952 79.9125 59.952, H83.7525C85.7365 59.952 87.3045 59.248 88.4565 57.84C89.6725 56.368 90.2805 54.224 90.2805 51.408V25.104Z" />
                    <path className="fill-main letter-s" d="M112 66.576V57.648C115.559 58.928 118.631 60.144 121.703 61.296C124.839 62.448 127.847 62.896 130.727 62.64C131.623 62.64 132.487 62.608 133.319 62.544C134.151 62.416 134.887 62.224 135.527 61.968C136.231 61.712 136.839 61.328 137.351 60.816C137.863 60.24 138.215 59.504 138.407 58.608C138.663 57.52 138.535 56.56 138.023 55.728C137.575 54.832 136.935 54.064 136.103 53.424C135.271 52.784 134.343 52.272 133.319 51.888C132.295 51.504 131.399 51.216 130.631 51.024C128.071 50.384 125.671 49.776 123.431 49.2C121.255 48.56 119.367 47.76 117.767 46.8C116.167 45.84 114.887 44.624 113.927 43.152C113.031 41.68 112.583 39.792 112.583 37.488C112.583 34.416 113.415 31.888 115.079 29.904C116.807 27.92 118.951 26.416 121.511 25.392C124.071 24.304 126.791 23.696 129.671 23.568C132.615 23.376 135.335 23.568 137.831 24.144C139.687 24.592 141.479 25.136 143.207 25.776C144.935 26.416 146.535 27.28 148.007 28.368V38.064C146.919 37.168 145.863 36.4 144.839 35.76C143.815 35.056 142.567 34.384 141.095 33.744C140.135 33.296 139.079 32.944 137.927 32.688C136.775 32.368 135.623 32.208 134.471 32.208C133.319 32.144 132.167 32.24 131.015 32.496C129.927 32.752 128.935 33.232 128.039 33.936C127.335 34.512 126.887 35.152 126.695 35.856C126.503 36.496 126.503 37.136 126.695 37.776C126.951 38.416 127.335 39.024 127.847 39.6C128.359 40.112 128.967 40.464 129.671 40.656C132.295 41.488 134.599 42.256 136.583 42.96C138.631 43.6 140.775 44.368 143.015 45.264C144.807 46.032 146.247 46.896 147.335 47.856C148.423 48.816 149.255 49.872 149.831 51.024C150.407 52.112 150.791 53.264 150.983 54.48C151.175 55.696 151.271 56.912 151.271 58.128C151.271 60.624 150.631 62.768 149.351 64.56C148.135 66.352 146.535 67.824 144.551 68.976C142.631 70.128 140.455 70.96 138.023 71.472C135.655 71.984 133.319 72.24 131.015 72.24C127.559 72.24 124.327 71.76 121.319 70.8C118.375 69.84 115.431 68.432 112.487 66.576Z" />
                    <path className="fill-main letter-t" d="M191 24.24V33.744, H180.907V70.8, H166.795V33.744, H156.619V24.24, H166.795V3.69599, H180.907V24.24, H191.179Z" />
                    <path className="fill-main letter-i" d="M199 16.464V5.13599, H210.981V16.464, H199.365ZM199.365 70.8V25.2, H210.981V70.8, H199.365Z"/>
                    <path className="fill-main letter-n" d="M254 70.8V45.168C254.842 44.208 254.65 43.248 254.266 42.288C253.946 41.328 253.466 40.496 252.826 39.792C252.186 39.088 251.45 38.512 250.618 38.064C249.85 37.616 249.05 37.392 248.218 37.392, H241.69C240.986 37.392 240.25 37.552 239.482 37.872C238.778 38.192 238.106 38.64 237.466 39.216C236.826 39.792 236.282 40.464 235.834 41.232C235.45 42 235.258 42.8 235.258 43.632V70.8, H221.146V24.048, H235.258V27.888C236.154 27.376 237.21 26.96 238.426 26.64C239.642 26.256 240.794 26.064 241.882 26.064, H248.89C251.066 26.064 253.114 26.448 255.034 27.216C256.954 27.984 258.618 29.072 260.026 30.48C261.434 31.888 262.554 33.648 263.386 35.76C264.218 37.808 264.634 40.112 264.634 42.672V70.8, H254.842Z" />
                </g>
            </svg> 

            <svg viewBox="260 -10 300 100" fill="none" xmlns="http://www.w3.org/2000/svg" className="Reese">                
                <g className="shadow">
                    <path className="fill-main letter-r" d="M350 70.8, H333.872C334.32 69.968 334.672 69.136 334.928 68.304C335.248 67.472 335.44 66.576 335.504 65.616C335.632 64.592 335.695 63.44 335.695 62.16C335.759 60.88 335.792 59.344 335.792 57.552C335.792 55.184 335.631 53.136 335.311 51.408C335.055 49.616 334.479 48.144 333.583 46.992C332.751 45.776 331.536 44.88 329.936 44.304C328.336 43.728 326.192 43.44 323.504 43.44, H312.656V70.8, H296.048V0.911987, H329.359C333.967 0.911987 337.68 1.67999 340.496 3.21599C343.312 4.68799 345.487 6.47999 347.023 8.59199C348.559 10.64 349.552 12.784 350 15.024C350.512 17.264 350.768 19.152 350.768 20.688C350.768 24.528 349.904 27.952 348.176 30.96C346.512 33.968 343.952 36.048 340.496 37.2C341.904 37.84 343.216 38.512 344.432 39.216C345.648 39.92 346.672 40.912 347.504 42.192C348.4 43.408 349.072 45.04 349.52 47.088C350.032 49.072 350.288 51.696 350.288 54.96V70.8ZM325.52 31.632C329.296 31.632 332.111 30.928 333.967 29.52C335.823 28.048 336.751 25.584 336.751 22.128C336.751 21.232 336.623 20.272 336.367 19.248C336.111 18.16 335.6 17.168 334.832 16.272C334.128 15.312 333.103 14.544 331.759 13.968C330.415 13.328 328.688 13.008 326.576 13.008, H312.656V31.632, H325.52Z"/>
                    <path className="fill-main letter-e" d="M380 25.104C382.439 25.104 384.583 25.456 386.631 26.16C388.679 26.864 390.471 27.856 392.007 29.136C393.607 30.416 394.855 32.016 395.751 33.936C396.711 35.792 397.191 37.872 397.191 40.176V51.024, H368.679V55.44C368.679 56.016 368.775 56.72 368.967 57.552C369.223 58.384 369.639 59.184 370.215 59.952C370.791 60.656 371.591 61.264 372.615 61.776C373.639 62.288 374.951 62.544 376.551 62.544, H380.295C384.903 62.544 387.207 60.432 387.207 56.208, H398.055V59.088C398.055 61.392 397.607 63.312 396.711 64.848C395.815 66.32 394.631 67.504 393.159 68.4C391.687 69.296 389.991 69.904 388.071 70.224C386.151 70.608 384.167 70.8 382.119 70.8, H372.903C371.303 70.8 369.639 70.576 367.911 70.128C366.247 69.68 364.647 68.912 363.111 67.824C361.639 66.8 360.359 65.424 359.271 63.696C358.183 61.904 357.447 59.728 357.063 57.168V40.176C357.063 38.064 357.543 36.112 358.503 34.32C359.463 32.464 360.743 30.864 362.343 29.52C363.943 28.112 365.799 27.024 367.911 26.256C370.087 25.488 372.359 25.104 374.727 25.104, H380.199ZM368.679 43.824, H386.247V40.944C386.247 40.112 386.023 39.312 385.575 38.544C385.191 37.712 384.647 37.008 383.943 36.432C383.239 35.792 382.439 35.312 381.543 34.992C380.647 34.608 379.751 34.416 378.855 34.416, H375.783C374.887 34.416 373.991 34.608 373.095 34.992C372.263 35.312 371.495 35.792 370.791 36.432C370.151 37.008 369.639 37.712 369.255 38.544C368.871 39.312 368.679 40.112 368.679 40.944V43.824Z" />
                    <path className="fill-main letter-e" d="M427 25.104C430.158 25.104 432.302 25.456 434.35 26.16C436.398 26.864 438.19 27.856 439.726 29.136C441.326 30.416 442.574 32.016 443.47 33.936C444.43 35.792 444.91 37.872 444.91 40.176V51.024, H416.398V55.44C416.398 56.016 416.494 56.72 416.686 57.552C416.942 58.384 417.358 59.184 417.934 59.952C418.51 60.656 419.31 61.264 420.334 61.776C421.358 62.288 422.67 62.544 424.27 62.544, H428.014C432.622 62.544 434.926 60.432 434.926 56.208, H445.774V59.088C445.774 61.392 445.326 63.312 444.43 64.848C443.534 66.32 442.35 67.504 440.878 68.4C439.406 69.296 437.71 69.904 435.79 70.224C433.87 70.608 431.886 70.8 429.838 70.8, H420.622C419.022 70.8 417.358 70.576 415.63 70.128C413.966 69.68 412.366 68.912 410.83 67.824C409.358 66.8 408.078 65.424 406.99 63.696C405.902 61.904 405.166 59.728 404.782 57.168V40.176C404.782 38.064 405.262 36.112 406.222 34.32C407.182 32.464 408.462 30.864 410.062 29.52C411.662 28.112 413.518 27.024 415.63 26.256C417.806 25.488 420.078 25.104 422.446 25.104, H427.918ZM416.398 43.824, H433.966V40.944C433.966 40.112 433.742 39.312 433.294 38.544C432.91 37.712 432.366 37.008 431.662 36.432C430.958 35.792 430.158 35.312 429.262 34.992C428.366 34.608 427.47 34.416 426.574 34.416, H423.502C422.606 34.416 421.71 34.608 420.814 34.992C419.982 35.312 419.214 35.792 418.51 36.432C417.87 37.008 417.358 37.712 416.974 38.544C416.59 39.312 416.398 40.112 416.398 40.944V43.824Z"/>
                    <path className="fill-main letter-s" d="M453 66.576V57.648C456.34 58.928 459.412 60.144 462.484 61.296C465.62 62.448 468.629 62.896 471.509 62.64C472.405 62.64 473.268 62.608 474.1 62.544C474.932 62.416 475.669 62.224 476.309 61.968C477.013 61.712 477.621 61.328 478.133 60.816C478.645 60.24 478.997 59.504 479.189 58.608C479.445 57.52 479.317 56.56 478.805 55.728C478.357 54.832 477.716 54.064 476.884 53.424C476.052 52.784 475.124 52.272 474.1 51.888C473.076 51.504 472.181 51.216 471.413 51.024C468.853 50.384 466.452 49.776 464.212 49.2C462.036 48.56 460.148 47.76 458.548 46.8C456.948 45.84 455.668 44.624 454.708 43.152C453.812 41.68 453.365 39.792 453.365 37.488C453.365 34.416 454.197 31.888 455.861 29.904C457.589 27.92 459.733 26.416 462.293 25.392C464.853 24.304 467.573 23.696 470.453 23.568C473.397 23.376 476.117 23.568 478.613 24.144C480.469 24.592 482.26 25.136 483.988 25.776C485.716 26.416 487.317 27.28 488.789 28.368V38.064C487.701 37.168 486.645 36.4 485.621 35.76C484.597 35.056 483.348 34.384 481.876 33.744C480.916 33.296 479.86 32.944 478.708 32.688C477.556 32.368 476.405 32.208 475.253 32.208C474.101 32.144 472.949 32.24 471.797 32.496C470.709 32.752 469.716 33.232 468.82 33.936C468.116 34.512 467.669 35.152 467.477 35.856C467.285 36.496 467.285 37.136 467.477 37.776C467.733 38.416 468.117 39.024 468.629 39.6C469.141 40.112 469.749 40.464 470.453 40.656C473.077 41.488 475.381 42.256 477.365 42.96C479.413 43.6 481.557 44.368 483.797 45.264C485.589 46.032 487.029 46.896 488.117 47.856C489.205 48.816 490.037 49.872 490.613 51.024C491.189 52.112 491.572 53.264 491.764 54.48C491.956 55.696 492.053 56.912 492.053 58.128C492.053 60.624 491.413 62.768 490.133 64.56C488.917 66.352 487.317 67.824 485.333 68.976C483.413 70.128 481.237 70.96 478.805 71.472C476.437 71.984 474.101 72.24 471.797 72.24C468.341 72.24 465.108 71.76 462.1 70.8C459.156 69.84 456.212 68.432 453.268 66.576Z"/>
                    <path className="fill-main letter-e" d="M520 25.104C523.064 25.104 525.208 25.456 527.256 26.16C529.304 26.864 531.096 27.856 532.632 29.136C534.232 30.416 535.48 32.016 536.376 33.936C537.336 35.792 537.816 37.872 537.816 40.176V51.024, H509.304V55.44C509.304 56.016 509.4 56.72 509.592 57.552C509.848 58.384 510.264 59.184 510.84 59.952C511.416 60.656 512.216 61.264 513.24 61.776C514.264 62.288 515.576 62.544 517.176 62.544, H520.92C525.528 62.544 527.832 60.432 527.832 56.208, H538.68V59.088C538.68 61.392 538.232 63.312 537.336 64.848C536.44 66.32 535.256 67.504 533.784 68.4C532.312 69.296 530.616 69.904 528.696 70.224C526.776 70.608 524.792 70.8 522.744 70.8, H513.528C511.928 70.8 510.264 70.576 508.536 70.128C506.872 69.68 505.272 68.912 503.736 67.824C502.264 66.8 500.984 65.424 499.896 63.696C498.808 61.904 498.072 59.728 497.688 57.168V40.176C497.688 38.064 498.168 36.112 499.128 34.32C500.088 32.464 501.368 30.864 502.968 29.52C504.568 28.112 506.424 27.024 508.536 26.256C510.712 25.488 512.984 25.104 515.352 25.104, H520.824ZM509.304 43.824, H526.872V40.944C526.872 40.112 526.648 39.312 526.2 38.544C525.816 37.712 525.272 37.008 524.568 36.432C523.864 35.792 523.064 35.312 522.168 34.992C521.272 34.608 520.376 34.416 519.48 34.416, H516.408C515.512 34.416 514.616 34.608 513.72 34.992C512.888 35.312 512.12 35.792 511.416 36.432C510.776 37.008 510.264 37.712 509.88 38.544C509.496 39.312 509.304 40.112 509.304 40.944V43.824Z"/>
                </g>
                
                <g id="Reese" className="name">
                    <path className="fill-main letter-r" d="M350 70.8, H333.872C334.32 69.968 334.672 69.136 334.928 68.304C335.248 67.472 335.44 66.576 335.504 65.616C335.632 64.592 335.695 63.44 335.695 62.16C335.759 60.88 335.792 59.344 335.792 57.552C335.792 55.184 335.631 53.136 335.311 51.408C335.055 49.616 334.479 48.144 333.583 46.992C332.751 45.776 331.536 44.88 329.936 44.304C328.336 43.728 326.192 43.44 323.504 43.44, H312.656V70.8, H296.048V0.911987, H329.359C333.967 0.911987 337.68 1.67999 340.496 3.21599C343.312 4.68799 345.487 6.47999 347.023 8.59199C348.559 10.64 349.552 12.784 350 15.024C350.512 17.264 350.768 19.152 350.768 20.688C350.768 24.528 349.904 27.952 348.176 30.96C346.512 33.968 343.952 36.048 340.496 37.2C341.904 37.84 343.216 38.512 344.432 39.216C345.648 39.92 346.672 40.912 347.504 42.192C348.4 43.408 349.072 45.04 349.52 47.088C350.032 49.072 350.288 51.696 350.288 54.96V70.8ZM325.52 31.632C329.296 31.632 332.111 30.928 333.967 29.52C335.823 28.048 336.751 25.584 336.751 22.128C336.751 21.232 336.623 20.272 336.367 19.248C336.111 18.16 335.6 17.168 334.832 16.272C334.128 15.312 333.103 14.544 331.759 13.968C330.415 13.328 328.688 13.008 326.576 13.008, H312.656V31.632, H325.52Z"/>
                    <path className="fill-main letter-e" d="M380 25.104C382.439 25.104 384.583 25.456 386.631 26.16C388.679 26.864 390.471 27.856 392.007 29.136C393.607 30.416 394.855 32.016 395.751 33.936C396.711 35.792 397.191 37.872 397.191 40.176V51.024, H368.679V55.44C368.679 56.016 368.775 56.72 368.967 57.552C369.223 58.384 369.639 59.184 370.215 59.952C370.791 60.656 371.591 61.264 372.615 61.776C373.639 62.288 374.951 62.544 376.551 62.544, H380.295C384.903 62.544 387.207 60.432 387.207 56.208, H398.055V59.088C398.055 61.392 397.607 63.312 396.711 64.848C395.815 66.32 394.631 67.504 393.159 68.4C391.687 69.296 389.991 69.904 388.071 70.224C386.151 70.608 384.167 70.8 382.119 70.8, H372.903C371.303 70.8 369.639 70.576 367.911 70.128C366.247 69.68 364.647 68.912 363.111 67.824C361.639 66.8 360.359 65.424 359.271 63.696C358.183 61.904 357.447 59.728 357.063 57.168V40.176C357.063 38.064 357.543 36.112 358.503 34.32C359.463 32.464 360.743 30.864 362.343 29.52C363.943 28.112 365.799 27.024 367.911 26.256C370.087 25.488 372.359 25.104 374.727 25.104, H380.199ZM368.679 43.824, H386.247V40.944C386.247 40.112 386.023 39.312 385.575 38.544C385.191 37.712 384.647 37.008 383.943 36.432C383.239 35.792 382.439 35.312 381.543 34.992C380.647 34.608 379.751 34.416 378.855 34.416, H375.783C374.887 34.416 373.991 34.608 373.095 34.992C372.263 35.312 371.495 35.792 370.791 36.432C370.151 37.008 369.639 37.712 369.255 38.544C368.871 39.312 368.679 40.112 368.679 40.944V43.824Z" />
                    <path className="fill-main letter-e" d="M427 25.104C430.158 25.104 432.302 25.456 434.35 26.16C436.398 26.864 438.19 27.856 439.726 29.136C441.326 30.416 442.574 32.016 443.47 33.936C444.43 35.792 444.91 37.872 444.91 40.176V51.024, H416.398V55.44C416.398 56.016 416.494 56.72 416.686 57.552C416.942 58.384 417.358 59.184 417.934 59.952C418.51 60.656 419.31 61.264 420.334 61.776C421.358 62.288 422.67 62.544 424.27 62.544, H428.014C432.622 62.544 434.926 60.432 434.926 56.208, H445.774V59.088C445.774 61.392 445.326 63.312 444.43 64.848C443.534 66.32 442.35 67.504 440.878 68.4C439.406 69.296 437.71 69.904 435.79 70.224C433.87 70.608 431.886 70.8 429.838 70.8, H420.622C419.022 70.8 417.358 70.576 415.63 70.128C413.966 69.68 412.366 68.912 410.83 67.824C409.358 66.8 408.078 65.424 406.99 63.696C405.902 61.904 405.166 59.728 404.782 57.168V40.176C404.782 38.064 405.262 36.112 406.222 34.32C407.182 32.464 408.462 30.864 410.062 29.52C411.662 28.112 413.518 27.024 415.63 26.256C417.806 25.488 420.078 25.104 422.446 25.104, H427.918ZM416.398 43.824, H433.966V40.944C433.966 40.112 433.742 39.312 433.294 38.544C432.91 37.712 432.366 37.008 431.662 36.432C430.958 35.792 430.158 35.312 429.262 34.992C428.366 34.608 427.47 34.416 426.574 34.416, H423.502C422.606 34.416 421.71 34.608 420.814 34.992C419.982 35.312 419.214 35.792 418.51 36.432C417.87 37.008 417.358 37.712 416.974 38.544C416.59 39.312 416.398 40.112 416.398 40.944V43.824Z"/>
                    <path className="fill-main letter-s" d="M453 66.576V57.648C456.34 58.928 459.412 60.144 462.484 61.296C465.62 62.448 468.629 62.896 471.509 62.64C472.405 62.64 473.268 62.608 474.1 62.544C474.932 62.416 475.669 62.224 476.309 61.968C477.013 61.712 477.621 61.328 478.133 60.816C478.645 60.24 478.997 59.504 479.189 58.608C479.445 57.52 479.317 56.56 478.805 55.728C478.357 54.832 477.716 54.064 476.884 53.424C476.052 52.784 475.124 52.272 474.1 51.888C473.076 51.504 472.181 51.216 471.413 51.024C468.853 50.384 466.452 49.776 464.212 49.2C462.036 48.56 460.148 47.76 458.548 46.8C456.948 45.84 455.668 44.624 454.708 43.152C453.812 41.68 453.365 39.792 453.365 37.488C453.365 34.416 454.197 31.888 455.861 29.904C457.589 27.92 459.733 26.416 462.293 25.392C464.853 24.304 467.573 23.696 470.453 23.568C473.397 23.376 476.117 23.568 478.613 24.144C480.469 24.592 482.26 25.136 483.988 25.776C485.716 26.416 487.317 27.28 488.789 28.368V38.064C487.701 37.168 486.645 36.4 485.621 35.76C484.597 35.056 483.348 34.384 481.876 33.744C480.916 33.296 479.86 32.944 478.708 32.688C477.556 32.368 476.405 32.208 475.253 32.208C474.101 32.144 472.949 32.24 471.797 32.496C470.709 32.752 469.716 33.232 468.82 33.936C468.116 34.512 467.669 35.152 467.477 35.856C467.285 36.496 467.285 37.136 467.477 37.776C467.733 38.416 468.117 39.024 468.629 39.6C469.141 40.112 469.749 40.464 470.453 40.656C473.077 41.488 475.381 42.256 477.365 42.96C479.413 43.6 481.557 44.368 483.797 45.264C485.589 46.032 487.029 46.896 488.117 47.856C489.205 48.816 490.037 49.872 490.613 51.024C491.189 52.112 491.572 53.264 491.764 54.48C491.956 55.696 492.053 56.912 492.053 58.128C492.053 60.624 491.413 62.768 490.133 64.56C488.917 66.352 487.317 67.824 485.333 68.976C483.413 70.128 481.237 70.96 478.805 71.472C476.437 71.984 474.101 72.24 471.797 72.24C468.341 72.24 465.108 71.76 462.1 70.8C459.156 69.84 456.212 68.432 453.268 66.576Z"/>
                    <path className="fill-main letter-e" d="M520 25.104C523.064 25.104 525.208 25.456 527.256 26.16C529.304 26.864 531.096 27.856 532.632 29.136C534.232 30.416 535.48 32.016 536.376 33.936C537.336 35.792 537.816 37.872 537.816 40.176V51.024, H509.304V55.44C509.304 56.016 509.4 56.72 509.592 57.552C509.848 58.384 510.264 59.184 510.84 59.952C511.416 60.656 512.216 61.264 513.24 61.776C514.264 62.288 515.576 62.544 517.176 62.544, H520.92C525.528 62.544 527.832 60.432 527.832 56.208, H538.68V59.088C538.68 61.392 538.232 63.312 537.336 64.848C536.44 66.32 535.256 67.504 533.784 68.4C532.312 69.296 530.616 69.904 528.696 70.224C526.776 70.608 524.792 70.8 522.744 70.8, H513.528C511.928 70.8 510.264 70.576 508.536 70.128C506.872 69.68 505.272 68.912 503.736 67.824C502.264 66.8 500.984 65.424 499.896 63.696C498.808 61.904 498.072 59.728 497.688 57.168V40.176C497.688 38.064 498.168 36.112 499.128 34.32C500.088 32.464 501.368 30.864 502.968 29.52C504.568 28.112 506.424 27.024 508.536 26.256C510.712 25.488 512.984 25.104 515.352 25.104, H520.824ZM509.304 43.824, H526.872V40.944C526.872 40.112 526.648 39.312 526.2 38.544C525.816 37.712 525.272 37.008 524.568 36.432C523.864 35.792 523.064 35.312 522.168 34.992C521.272 34.608 520.376 34.416 519.48 34.416, H516.408C515.512 34.416 514.616 34.608 513.72 34.992C512.888 35.312 512.12 35.792 511.416 36.432C510.776 37.008 510.264 37.712 509.88 38.544C509.496 39.312 509.304 40.112 509.304 40.944V43.824Z"/>
                </g>
            </svg> 
            
            <h1 className="dash">-</h1>

        </div>

    )

}

export default DustinReeseText;