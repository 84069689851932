/* Contact */

// React
import { useEffect } from 'react';

// Bootstrap
import { Container, Row, Col, Button, Image } from 'react-bootstrap';

// Global settings
import { useGlobals } from '../Global';

// Quest Icon
import QuestIcon from '../components/QuestIcon'

// Email button
export function emailBtn( e ) {
  let emailSubject = "Reach out via your website";
  let emailBody = "Hello Dustin,  \n\n Thank you for being so amazing and awesome. I was truly blown away by your creativity. \n - My name is: \n - I am reaching out to you to talk about: \n\n - Please consider the following time tables regarding said project: \n\n I hope you are having a fantastic day! ";
  
  // URL encode it
  let mailToLink = "mailto:" + process.env.REACT_APP_EMAIL + "?subject=" + encodeURIComponent( emailSubject ) + "&body=" + encodeURIComponent( emailBody );
  
  // Send out the link so it do the emailing
  window.open( mailToLink, "_blank" );
  e.preventDefault();

}

// The work
const Contact = ( { id, className } ) => {  
    // Globals
    const globals = useGlobals();

    // Scroll to top when page loads
    useEffect(() => {
      window.scrollTo( 0, 0 )
    }, [] )

    return (
      <div id="Contact">
        <Container className="pt-6 pb-6">
        <div className="bg-abstract-colors bg-fullScreen"></div>

            <Row id={ id } className={ className + " text-center section justify-content-md-center" } >
              <Col md="12">
                <h2>Contact Dustin today!</h2>
                <hr />

              </Col>

              <Col md="6">                
                <p className="text-start">
                  Thank you for taking the time to get to know me. I want you to know that I'm just one simple email away. Click on the button below and shoot me an email letting me know the following information:
                </p>

                <ul className="text-start">
                  <li>Your Name</li>
                  <li>A brief summary of the project you want to work on</li>
                  <li>Any time tables that need to be considered for said projects</li>
                </ul>

                <Button variant="primary" title="Email Dustin today" onClick={ emailBtn }> Email Dustin today!</Button>

                {
                  // If we're using the old school theme
                  globals.vals.theme === "oldSchool" ? ( <div className="row mt-3">
                    <Image src={ require( "../assets/images/email.gif" ) } title="Email me today!" className="img-fluid" />
                    </div>) : ""
                }
                
                <QuestIcon id="key3" />

              </Col>
                        
            </Row>

        </Container>
        
      </div>
  );
};
  
export default Contact;