// Layout for all of site

// React
import { useState, useEffect } from 'react';

// Router
import { Outlet, Link, useSearchParams, useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

// GA4 - Google Analytics
import ReactGA from 'react-ga4';

// Global Values
import { useGlobals } from '../Global';

// Bootstrap
import { Nav, Navbar } from 'react-bootstrap';
import { Container, Row, Col  } from 'react-bootstrap';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faArrowUp } from '@fortawesome/free-solid-svg-icons';

// Cookies
import Cookies from 'universal-cookie';

///////////////////////////////////////////////////

// Settings
import Settings from '../components/Settings'

// Components
import CookieConsent from '../components/CookieConsent';

// The work
const Layout = () => {
  // Track page change
  let location = useLocation();

  useEffect(() => {
    console.log( "page change: ", location.pathname )

    ReactGA.event({
      category: "page_change",
      action: location.pathname

    });

  }, [ location ] );


  // Cookie consent reload
  const [seed, setSeed] = useState( 1 );
  const reset = () => {
    // Cookie
    let cookies = new Cookies( null, { path: '/' } );
    cookies.set( 'cookie_consent', null, { path: '/' } );

    setSeed( Math.random() );
      
  }

  // Get URL Perameters
  const [ queryParameters ] = useSearchParams()

  ///////////////////
  
  // Global variables
  const globals = useGlobals();

  // If we have a new resume to use
  if( queryParameters.get( "resume" ) ){
    console.log( "We have some data to update!" )
    console.log( "URL Queries: " + queryParameters.get( "resume" ) )

    // This resume doesn't match the previous one
    if( globals.vals.resume !== queryParameters.get( "resume" ) ){
      globals.setGVars( {
        ...globals.vals,
        resume: queryParameters.get( "resume" )

      } )

    }

  }
  
  ///////////////////////

  // Scroll to top of page from footer
  const [ topPage, scrollTop ] = useState( false );
  useEffect(() => {
    window.scrollTo( 0, 0 )
  }, [ topPage ] )

  function topOfPage(){
    scrollTop( !topPage )

  }

  // Create nav link
  function createNavLink( id, displayName, dislplayRoot ){
    // eventKey="home"

    return(
      <HashLink to={ ( dislplayRoot ? "/" : "/" + id ) } id={ "nav-" + id } className="nav-link"  >{ displayName }</HashLink>
    )
  }

  ///////////////////////

  // The work
  return (
    <div className="d-flex flex-column vh-100" id="website">
      <CookieConsent
        id="cookieConsentModal"
        key={ seed }
        classes="bw-target"

      />
      
      <Settings
        id="settings"
        classes="bw-target"
      />

      <Navbar id="nav" expand="md" className={ "bg-body-tertiary bw-target" } sticky='true' collapseOnSelect  >
        <Container>
          <Link to="/" className="text-decoration-none navbar-brand">DustinReese.com</Link>
          
          <Navbar.Toggle aria-controls="navbar-nav" />

          <Navbar.Collapse id="navbar-nav" className="justify-content-end">
            <Nav >
              { createNavLink( "home", "Home", true ) }
              { createNavLink( "about", "About") }
              { createNavLink( "previousClients", "Previous Clients") }
              { createNavLink( "resume", "Resume") }
              { createNavLink( "Contact", "Contact") }
              { createNavLink( "quest", "Quest") }
                
              <Nav.Link className='bl' href={ process.env.REACT_APP_LINKIN_URL } title="Find Dustin on LinkedIn" target="_linkedin">
                LinkedIn <FontAwesomeIcon icon={ faArrowUpRightFromSquare } />
              </Nav.Link>

            </Nav>

          </Navbar.Collapse>

        </Container>

      </Navbar>

      <main role="main" className="flex-grow-1 bw-target" id="main">
          <Outlet/>

      </main>      

      <footer id="footer" className="bw-target">
        <Container>
          <Row>
            <Col md="6">
                <ul className="d-flex flex-column flex-md-row">
                  <li><div onClick={ topOfPage } className="aFake" title="Top of page" >Top <FontAwesomeIcon icon={ faArrowUp } /></div> </li>
                  <li><HashLink to="/Contact" title="Contact Dustin" >Contact</HashLink> </li>
                  <li><HashLink to="#nav" onClick={ reset } >Cookie Consent</HashLink> </li>
                  <li><HashLink to="/404" id="nav-404" >404</HashLink> </li>
                  <li><a href={ process.env.REACT_APP_LINKIN_URL } target="_linkedin">LinkedIn <FontAwesomeIcon icon={ faArrowUpRightFromSquare } /></a></li>
                </ul>
                
            </Col>
            <Col md="6" className="d-flex justify-content-md-end">
              Site design and code by Dustin Reese ©2024

            </Col>

          </Row>

        </Container>

      </footer>

    </div>

  )

};

export default Layout;