/* Cookie consent popup */

// React
import { useState, useEffect } from 'react';

// Cookies
import Cookies from 'universal-cookie';

/* Bootstrap */
import { Modal, Button } from 'react-bootstrap';

// Cookie
const cookies = new Cookies( null, { path: '/' } );

// Check if consent is already given
export function cookieConsentGiven() {
    // If we have the cookie
    if( cookies.get( 'cookie_consent' ) ) {
        return cookies.get( 'cookie_consent' );
    }

    // If we don't have cookies, load
    return null;

}

// The work
function CookieConsent( { id, classes, resetCookie } ) {    
     // If we're resetting the cookie
     if( resetCookie === true ){
        cookies.set( 'cookie_consent', null, { path: '/' } );
        
    }

    // Show consent modal
    const [ show, setShow ] = useState( false );

    // Show/hide modal
    const handleShow = () => setShow( true );
    const handleClose = () => setShow( false );

    // Store consent
    const [ consentGiven, setConsentGiven ] = useState( cookies.get( 'cookie_consent' ) );

    // When we load the page
    useEffect(() => {
        setConsentGiven( cookieConsentGiven() );

    }, []);

    // If we don't have consent yet
    useEffect(() => {
        if( consentGiven == null ) {
            handleShow();
        }

    }, [ consentGiven ]);

    // Accept cookies
    const handleAcceptCookies = () => {
        cookies.set( 'cookie_consent', 'yes', { path: '/' } );
        console.log( "Consent given - thank you for trusting me." )
        setConsentGiven('yes');
        
        handleClose();
    };

    // Decline cookies
    const handleDeclineCookies = () => {
        cookies.set( 'cookie_consent', 'no', { path: '/' } );
        console.log( "Consent declined - I get it. No problem!" )

        // Remove Google Analytics Cookies
        cookies.set( '_ga', '', { path: '/' } );
        cookies.set( '_ga_XEGLLEPM5B', '', { path: '/' } )        

        setConsentGiven('no');
        
        handleClose();
        
    };


    // What we show
    return (
        <Modal show={ show } onHide={ handleClose } id={ id + "_modal" } className={ classes } autoFocus={ true } backdrop='static'>
            <Modal.Header>
            <Modal.Title>Cookie Consent</Modal.Title>

            </Modal.Header>

            <Modal.Body>
                <div>
                    <p>I respect your right to privacy, which is why I'm letting you know that I use cookies on this website to track usage, make site improvements and remember your settings. You also have the right to remove said cookies and browse my site anonymously, and that's cool with me!</p>

                    <p>Please accept or decline cookies to continue onto DustinReese.com</p>
                </div>

            </Modal.Body>

            <Modal.Footer>
            <Button variant="secondary" onClick={ handleDeclineCookies } className="text-uppercase" title="Decline Cookie Consent">Decline</Button>
            <Button variant="primary" onClick={ handleAcceptCookies }  title="Accept Cookie Consent">Accept</Button>

            </Modal.Footer>

        </Modal>        
        
    );
}

export default CookieConsent;