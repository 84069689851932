/* 404 Missing Page */

// Hash Link
import { HashLink } from 'react-router-hash-link';

// Bootstrap
import { Container, Row, Col, Button } from "react-bootstrap";

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMeteor, faHouse, faCarSide, faStore, faBuildingFlag, faCloud, faBuildingColumns } from '@fortawesome/free-solid-svg-icons';

// Quest Icon
import QuestIcon from '../components/QuestIcon'


// The work
const NoPage = () => {
    return (
      <div id="PageNotFound">
        <Container className="mb-3">
          <Row className="justify-content-center">
            <Col md="12" className="text-center">
              <h2>404 - Page not found</h2>

            </Col>
            
            <Col md="6" className="text-center">              
              <p>You probably don't want to be here much longer</p>

              <HashLink to="/" id="nav-home" className="nav-link"><Button>Quickly escape!</Button></HashLink>

              <QuestIcon id="key1" />

            </Col>
            
            <Col md="6">
              <div className="no-page-404">

                <div className="top">                  
                  <FontAwesomeIcon icon={ faMeteor }  />
                  <FontAwesomeIcon icon={ faCloud } className="one"/>
                  <FontAwesomeIcon icon={ faCloud } className="two"/>
                  <FontAwesomeIcon icon={ faCloud } className="three"/>

                </div>

                <div className="fg">
                  <FontAwesomeIcon icon={ faBuildingColumns }/>
                  <FontAwesomeIcon icon={ faBuildingFlag } />
                  <FontAwesomeIcon icon={ faStore } />
                  <FontAwesomeIcon icon={ faHouse } />

                </div>
                
                <div className="carDrive">
                  <FontAwesomeIcon icon={ faCarSide } /> 

                </div>         

              </div>

            </Col>

          </Row>

        </Container>        
        
      </div>
  );
};
  
export default NoPage;