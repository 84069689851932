/* Components - Quest clicky icon */

// React
import { useState } from 'react';

// React dom
import { HashLink } from 'react-router-hash-link';

// React Bootstrap
import { Modal } from 'react-bootstrap';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCookieBite, faCheck } from '@fortawesome/free-solid-svg-icons';

// Global Values
import { useGlobals } from '../Global';

////////////////////

// The Work
function QuestIcon( { id, classes } ) {
  // Modal
  const [ show, setShow] = useState( false );
  const handleClose = () => setShow(false);

  // Global variables
  const globals = useGlobals();

  // Update the global value
  function updateGlobal(){
    // Show the modal
    setShow( true );

    // Set our globals
    globals.setGVars( {
      ...globals.vals,
      ...globals.vals.cookies.keys[ id ] = true

    } )

  }

  return (
    <div className={ "d-flex justify-content-center " + classes } title="Collect cookie for your Quest">
      <div className={ "questIcon " + ( globals.vals.cookies.keys[ id ] ? "" : "clickMe" ) } 
        onClick={ updateGlobal } >  

        <div className="bg1" />
        <div className="bg2" />
        <div className="bg3" />

        <span className="fa-layers fa-fw">
          <FontAwesomeIcon
            icon={ faCookieBite }
            className={ globals.vals.cookies.keys[ id ] ? "" : "shake" } 
            title={ globals.vals.cookies.keys[ id ] ? "Collected" : "Click to collect!" }

          /> 

          { globals.vals.cookies.keys[ id ] ? <FontAwesomeIcon icon={ faCheck } onClick={ updateGlobal } /> : "" }

        </span>

        <div className="text">
          { globals.vals.cookies.keys[ id ] ? "Collected" : "Collect" }
        </div>        

      </div>

      <Modal show={ show } onHide={ handleClose } >
        <Modal.Header closeButton>
          <Modal.Title>Cookie collected!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          You found a cookie! For mor details, <HashLink to="/Quest">check out the Quest page</HashLink>.
          
        </Modal.Body>

      </Modal>

    </div>

  );

}

export default QuestIcon;