/* Quest Page */

// React
import { useState, useEffect, useCallback } from 'react';

// Bootstrap
import { Container, Row, Col, Image, Form, ListGroup } from "react-bootstrap";

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCookieBite, faHeartCrack } from '@fortawesome/free-solid-svg-icons';

// Global Values
import { useGlobals } from '../Global';

// Recipe
const cookieRecipe = {
    totalTime: "1 hour, 30 minutes",
    bakeTime: "12 minutes",
    temp: "375",
    ingredients: [
        { item: "All-purpose Flour", amount: "2 1/4 cups" },
        { item: "Baking Soda", amount: "1 teaspoon (tbs)" },
        { item: "Salt", amount: "1 teaspoon (tbs)" },
        { item: "Butter - softened", amount: "1 cup (2 sticks)" },
        { item: "Granulated Sugar", amount: "3/4 cup" },
        { item: "Brown Sugar", amount: "3/4 cup" },
        { item: "Vanilla Extract", amount: "1 teaspoon (tbs)" },
        { item: "Eggs", amount: "2" },
        { item: "Chocolate Chips", amount: "2 cups (one bag)" }
    ],
    directions:[
        "Preheat oven to 375",
        "Combine flour, baking soda, salt in a bowl. Then add granulated sugar and brown sugar to bowl and mix well.",
        "Add vanilla, eggs and butter one at a time, making sure to mix well after adding each item, until dough is creamy.",
        "Add chocalate chips 1 cup at a time and mix until chocolate is evenly (or at least as best as you can) as possible.",
        "Optional - Leave your dough in the fridge to cool for 1 hour",
        "Using a spoon, create a ball of cookie dough and then press flat onto baking sheet.",
        "Cook for 12 minutes",
        "Remove cookies from baking sheet and let cool for 5 minutes before enjoying!"
    ]

}

// Possible animals
const animals = [
    { animal: "chicken", url: "chicken.png" },
    { animal: "llama", url: "llama.png" },
    { animal: "peach", url: "peach.png" },
    { animal: "unicorn", url: "unicorn.png" }
]

// The work
const Quest = () => {
    // Which animal are we helping?
    const [ animalDistress, setAnimalDistress ] = useState( null );

    // Function to determine animal
    const pickAnimal = useCallback(() => {
        let random = Math.random();
        let key = Math.floor( random * animals.length );

        // Set animal
        setAnimalDistress( animals[ key ] )

    }, [ /* no dependencies */] );

    // Change our animal on reload
    useEffect( () => {
        pickAnimal();
        
    }, [ pickAnimal ] )

    // Global variables
    const globals = useGlobals();

    // Quest Status - Tracking
    function dispQuestIcon( key ){
        return(
            <div className={ "text-center flex-grow-1 " + ( globals.vals.cookies.keys[ key ] ? "found" : "" ) }>
                <div className="status">
                    <FontAwesomeIcon icon={ faCookieBite } />

                </div>
                
                <div>
                    { globals.vals.cookies.keys[ key ] ? "FOUND!" : "Missing" }

                </div>

            </div>

        )

    }


    // Quest code
    function dispQuest(){
        return(
            <Row className="justify-content-center">
                <Col md="4" className='position-relative animal-help'>
                    <div className="overflow-hidden" >
                        <Image src={ require( "../assets/images/" + animalDistress.url ) } title={"My poor " + animalDistress.animal + "!"} className="img-fluid" />

                        <div className='sad' >
                            <FontAwesomeIcon icon={ faHeartCrack } />
                        </div>

                    </div>

                    <div className="text-center">Please help my <span className="emphesis">{ animalDistress.animal }</span>!</div>
                    
                </Col>

                <Col md="8" lg="4" xl="4">
                    <div className="mission">
                        <h4>- <strong>Mission</strong> -</h4>

                        <div>
                            <h5>Find 'em cookies!</h5>
                            <p>My poor <span className="emphesis">{ animalDistress.animal }</span> has lost its cookies somewhere around here. You can tell it's extremely distressed, just look at those eyes!</p>
                            <p>Explore DustinReese.com to find the missing cookies and when you've found all of them, return here for a grand reward.</p>
                            <p>What reward? Well, my world famous <strong>cookie recipe!</strong></p>
                            <p>Please hurry, I don't know how much longer my beloved <span className="emphesis">{ animalDistress.animal }</span> will last...!</p>

                        </div>


                    </div>
                    

                </Col>
                
                <Col md="9" lg="4" xl="4" className="status">
                    <div className="body">
                        <h4>Status</h4>

                        <p>Here is a list of all the cookies that you've currently found!</p>

                        <div className="tracker">
                            { Object.keys( globals.vals.cookies.keys ).map( objKey => (              
                                <div key={ "key_status_" + objKey } className="flex-grow-1">
                                    { dispQuestIcon( objKey ) }

                                </div>

                            ))}                          

                        </div>

                    </div>
                    
                </Col>
            </Row>
        )
    }

    // Cookie recipe
    function dispRecipe() {
        return(
            <div>
                <Row>
                    <Col md="3">
                        <div className="mission">
                            <h4><strong>Mission</strong>: Completed!!!</h4>

                            <div>
                                <p>You have found all the missing cookies and my <span className="emphesis">{ animalDistress.animal }</span> looks so pleased. Truly, you are the hero this world deserves.</p>
                                <p>And as a sweet reward to helping me so, please enjoy my world famous (<i>totally not stolen</i>) cookie recipe!</p>

                            </div>


                        </div>

                        <div className="animal-help">
                            <Image src={ require( "../assets/images/" + animalDistress.url ) } title={ "My happy " + animalDistress.animal + "!" } className="img-fluid" />
                            <div className="text-center p-3 bg-white">Look at that happy little <span className="emphesis">{ animalDistress.animal }</span> dork.</div>

                        </div>

                    </Col>

                    <Col md="9" className="status">
                        <Row className="recipe body">
                            <Col md="4">
                                <h4>Ingredients</h4>

                                <ListGroup className="ingredients">
                                    <Form>
                                        { cookieRecipe.ingredients.map( ( thingy, i ) => (
                                            <ListGroup.Item key={ "ingredient_" + i }>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={ thingy.item }
                                                    label={ thingy.amount + " " + thingy.item }
                                                />

                                            </ListGroup.Item>

                                        )) }

                                    </Form>

                                </ListGroup>

                            </Col>

                            <Col md="8">
                                <h4>Directions</h4>
                                <ListGroup as="ol" numbered >
                                    { cookieRecipe.directions.map( ( step, i ) => (
                                        <ListGroup.Item key={ "directions_" + i }>
                                            { step }
                                        </ListGroup.Item>

                                    )) }

                                </ListGroup>

                            </Col>

                            <Col md="12">
                                <sub>
                                    Source: <a href="https://www.verybestbaking.com/toll-house/recipes/original-nestle-toll-house-chocolate-chip-cookies/" target="_blank" rel="noreferrer">Nestle Toll House</a>
                                </sub>

                            </Col>

                        </Row>

                    </Col>

                </Row>

            </div>
        )

    }

    // Check if we've got all our keys
    const [ allKeysFound, updateKeys ] = useState( false )

    // Check if quest is completed
    useEffect(() => {        
        if( globals.vals.cookies.keys.key1 && globals.vals.cookies.keys.key2 && globals.vals.cookies.keys.key3 ){
            updateKeys( true );

        }

    }, [ globals.vals.cookies.keys.key1, globals.vals.cookies.keys.key2, globals.vals.cookies.keys.key3 ] );

    return (
      <div id="Quest" className="pt-6 pb-6">
        <Container className="mb-3">
          <Row className="justify-content-center">
            <Col md="12" className="text-center">
              <h2>Quest - Theme: { globals.vals.theme }</h2>
              <h4>The great cookie search</h4>
              <hr />

            </Col>

          </Row>

            { animalDistress !== null ? ( allKeysFound ? dispRecipe() : dispQuest() ) : "" }

        </Container>        
        
      </div>
  );
};
  
export default Quest;