/* Index.js */

// React
import { React } from 'react';

// React DOM
import ReactDOM from 'react-dom/client';

// Web Vitals?
import reportWebVitals from './reportWebVitals';

// Global Variables
import { GlobalVar } from './Global';

// The actual app
import App from './App';

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/index.scss';


// The work
export default function Website() {

  return (
    <GlobalVar>
        <App />

    </GlobalVar>

  )
}

const root = ReactDOM.createRoot( document.getElementById('root') );
root.render( <Website /> );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
